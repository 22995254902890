import React from "react";
import ProductCard from "components/ProductCard";
import Heading from 'components/Heading'

import { 
    relatedProducts, 
    productCards, 
    relatedProductsHeading 
} from "./related-products.module.css"

function RelatedProducts( { products } ) {

    let productsJSX = products.map(( product ) => 
        <ProductCard product={product} key={product.id} hoverEffect={ true }/>
    );

    return (
        <section className={relatedProducts}>
            <div className={relatedProductsHeading}>
                <Heading level="h3">Related Products</Heading>
            </div>
            
            <div className={productCards}>
                { productsJSX }
            </div>
        </section>
    );
}

export default RelatedProducts