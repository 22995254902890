import React, { useState } from 'react'

import { gallerySection } from './gallery.module.scss'

function Gallery({imageUrls}) {
    const [activeIndex, setActiveIndex] = useState(0);

    const image_gallery = imageUrls.map((image, i) => {
        const isActive = i === activeIndex;
        const url = image.localFile.url

        const width = (() => {
            if (imageUrls.length === 6) return null;
            const predicted_width = 100 / imageUrls.length;
            const actual_width = predicted_width * 0.99;
            return actual_width;
        })();

        const galleryImage = {
            width: `${width}%`,
            opacity: `${isActive ? 1 : "auto"}`
        };

        return (
            <img
                style={galleryImage}
                src={url}
                role="button"
                aria-pressed={isActive ? true : false}
                tab-index="0"
                data-index={i}
                key={i}
                loading="lazy"
                onClick={e =>
                    setActiveIndex(parseInt(e.currentTarget.dataset.index))
                }
                alt=""
            />
        );
    });

    return (
        <section className={gallerySection}>
            <img
                src={imageUrls[activeIndex].localFile.url}
                alt=""
                loading="lazy"
                alt="gallery image"
            />
            
            {imageUrls.length > 1 &&
            <div>
                {image_gallery}
            </div> }
        </section>
    );
}

export default Gallery