import React from "react";
import { Link } from "gatsby"
import Heading from 'components/Heading'

import { productCard, productText, cardHoverEffect, colorText } from "./productCard.module.css"

function ProductCard({ product, hoverEffect, colors }) {

    let classes = [productCard]
    if (hoverEffect) classes.push(cardHoverEffect);

    return (
        <Link to={`/product/${ product.handle }`}>
            <div className={classes.join(' ')}>
                <img src={product.images[0].localFile.url} alt={product.title}/>
                <div className={productText}>
                    <Heading level='h4'>{product.title}</Heading>
                    <Heading level='h4'>{product.variants.length > 1 ? "From" : ""} £{Math.floor( product.priceRange.minVariantPrice.amount )}</Heading>
                    {colors && <p className={colorText}>{colors} {colors === 1 ? "colour" : "colours"}</p>}
                </div>
            </div>
        </Link>
    );
}

export default ProductCard