import React, { useState } from 'react'
import {Link} from 'gatsby'
import Heading from 'components/Heading'

import { cartItem, cartItemText, toBasketLink, topSection, label, priceRow, crossImg } from './cartItemCard.module.css'


function CartItemCard({ className, title, quantity, price, imageURL, options, exited }) {
    const [isVisible, setIsVisible] = useState(true)

    const exitPressed = () => {
        setIsVisible(false);
        exited();
    }

    return (
        <>
            {isVisible ?
                <div className={cartItem}>
                    <img src={imageURL} alt={title}/>

                    <div className={[className, cartItemText].join(' ')}>
                        <div className={topSection}>
                            <Heading level='h4'>{title}</Heading>
                        </div>

                        <p>{ options.map( opt => <><span className={label}>{opt.name}:</span> {opt.value}{` `}</> ) }  <span className={label}>Quantity:</span> {quantity}</p>
                        <div className={priceRow}><span className={label}>{price}</span> incl. VAT</div>
                        <Link to="/basket" className={toBasketLink}>Go to basket</Link>
                    </div>

                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={crossImg} onClick={exitPressed} > 
                        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="var(--seasonal-color)"/>
                    </svg>

                </div> : null
            }
        </>
    );
}

export default CartItemCard