import React, { useState, useContext } from 'react'
import { useMediaQuery } from "react-responsive"
import {Link} from 'gatsby'
import Button from 'components/Button'
import Heading from 'components/Heading'
import Gallery from 'components/Gallery'
import CartItemCard from 'components/CartItemCard'
import { StoreContext } from "context/store-context"
import { formatPrice } from "utils/format-price"

import { productSection, productInfo, selectRow, orange, cartItemCard, expandable } from './productBody.module.css'

function ProductBody( {
    product,
    quantities,
    category
}) {

    const { 
        title,
        description,
        images,
        variants
    } = product

    const { client, addVariantToCart, loading, checkout } = useContext(StoreContext)
    const hasVariants = variants.length > 1
    const hasImages = images.length > 0

    const [quantity, setQuantity] = useState(1)
    const [isSizeGuideExpanded, setIsSizeGuideExpanded] = useState(false)
    const [isShippingExpanded, setIsShippingExpanded] = useState(false)
    const [isInCart, setIsInCart] = useState(false)
    const isMobile  = useMediaQuery({  maxWidth: 650 })

    // Get selected objects and map them into an array of keys matching the option names
    const [options, setOptions] = useState( 
        Object.assign(
            ...variants[0].selectedOptions.map( ({ name, value }) => ({ [name] : value}) ) 
        )
    )
    const productVariant = client.product.helpers.variantForOptions(product, options) 
    const [available, setAvailable] = React.useState( productVariant.availableForSale )
    const [cartItem, setCartItem] = useState({productVariant : productVariant, quantity : quantity})

    let quantityOptions = quantities.map((item, i) => {
        return <option key={i}>{item}</option>
    });

    function resetPage() {
        setIsInCart(false)
    }

    function toggleIsSizeGuideExpanded() {
        setIsSizeGuideExpanded(!isSizeGuideExpanded)
    }

    function toggleIsShippingExpanded() {
        setIsShippingExpanded(!isShippingExpanded)
    }

    function handleQuantityChange(e) {
        setQuantity(e.target.value)
    }

    function handleOptionChange(optionName, optionValue) {
        setOptions( { ...options, [optionName]: optionValue } )
    }

    function handleAddToCartButton(e) {
        e.preventDefault()
        setCartItem({productVariant: productVariant, quantity : quantity})
        setIsInCart(true)
        addVariantToCart(productVariant.shopifyId, quantity)
        setTimeout(resetPage, 4000)
    }

    const itemAddedButtonStyle = isInCart ? {
        background : `var(--seasonal-color)`,
        cursor : `none`,
        pointerEvents: `none`,
        borderRadius: `8px`
    } : {}

    return (
        <section >
            {isInCart && !isMobile &&
            <CartItemCard 
                className={cartItemCard}
                exited={resetPage}
                title={title}
                options={cartItem.productVariant.selectedOptions}
                quantity={cartItem.quantity}
                price={ formatPrice( productVariant.priceV2.currencyCode, cartItem.quantity * productVariant.priceV2.amount )}
                imageURL={images[0].localFile.url}
            />
            }
        
            <div className={productSection}>
                <div>
                    <Gallery imageUrls={images}/>
                </div>
                
                <div>
                    <form>
                        <div className={productInfo}>
                            <div>
                                <Heading level="h5">
                                    <span className={orange}>
                                        <Link to={'/products/' + product.productType.toLowerCase()}>
                                            {product.productType + ' / '}
                                        </Link>
                                    </span> 
                                    {title}
                                </Heading>
                            </div>

                            <div>
                                <Heading level="h2">{title}</Heading>
                                <p>Product Code:{ productVariant.sku }</p>
                                <Heading level="h3">
                                    { formatPrice( productVariant.priceV2.currencyCode, productVariant.priceV2.amount) 
                                    }
                                </Heading>
                                <p>{description}</p>
                            </div>
                                                               
                            <div className={selectRow}>
                                {product.options.map ( option => <label htmlFor={`${option.name}`}>{option.name}
                                        <select id={`${option.name}`} onChange={ (e) => handleOptionChange(option.name, e.target.value) }>
                                            { option.values.map( value => <option key={value}>{value}</option>) }
                                        </select>
                                    </label>
                                )}
                                
                                <div>
                                    <label htmlFor="quantitySelect">Quantity</label> <br/>
                                    <select id="quantitySelect" onChange={handleQuantityChange}>
                                        {quantityOptions}
                                    </select>
                                </div>
                                
                                <Button 
                                    onClick={handleAddToCartButton} 
                                    style={itemAddedButtonStyle}
                                >
                                    {isInCart ? 'Added to Cart' : 'Add to Cart'}
                                </Button>

                            </div>

                            <div>
                                <div 
                                    className={expandable}
                                    onClick={toggleIsSizeGuideExpanded}
                                >
                                    <p>Size Guide</p>
                                    { isSizeGuideExpanded ?
                                        <svg width="15" height="15" viewBox="0 0 15 15" >
                                            <path d="M0 6h15v2.7h-15z" fill="#2F3542"/>
                                        </svg>
                                        :
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 8.57143H8.57143V15H6.42857V8.57143H0V6.42857H6.42857V0H8.57143V6.42857H15V8.57143Z" fill="#2F3542"/>
                                        </svg>
                                    }
                                </div>

                                { isSizeGuideExpanded &&
                                    <p>Sizing Guide for this product is currently unavailable</p>
                                }
                            </div>
                            
                            <div>
                                <div 
                                    className={expandable}
                                    onClick={toggleIsShippingExpanded}
                                >
                                    <p>Shipping &amp; Returns</p>
                                    { isShippingExpanded ?
                                        <svg width="15" height="15" viewBox="0 0 15 15" >
                                            <path d="M0 6h15v2.7h-15z" fill="#2F3542"/>
                                        </svg>
                                        :
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 8.57143H8.57143V15H6.42857V8.57143H0V6.42857H6.42857V0H8.57143V6.42857H15V8.57143Z" fill="#2F3542"/>
                                        </svg>
                                    }
                                </div>
                                {  isShippingExpanded &&
                                    <p>Shipping and Returns for this product are currently unavailable</p>
                                }
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
            
        </section>
    )
}

export default ProductBody