import * as React from 'react'
import { graphql } from "gatsby"

import Header from "components/Header"
import Footer from "components/Footer"
import ProductBody from "components/ProductBody"
import RelatedProducts from "components/RelatedProducts"

function Product({ pageContext, data }) {

    const { product, relatedProducts } = data;

    return (
        <main>
            <Header />
            <ProductBody
                product={product}
                quantities={ Array(5).fill().map((element, index) => index + 1) }
                category="Unisex / Hats /"
            />
            <RelatedProducts products={ relatedProducts.edges.map( ({ node }) => node )} />
            <Footer />
        </main>
    )
}

export const query = graphql`
    query productPage( $id: String ){
        product: shopifyProduct( id: {eq: $id} ){
            variants {
                availableForSale
                id
                priceV2 {
                  amount
                  currencyCode
                }
                shopifyId
                sku
                title
                selectedOptions {
                    name
                    value
                }
            }
            description
            handle
            title
            availableForSale
            productType
            priceRange {
                minVariantPrice {
                    amount
                }
            }
            images {
                id
                localFile{
                    url
                }
            }
            options {
                name
                values
            }
        }
        relatedProducts: allShopifyProduct(filter: {tags: {in: ["Hats"]}}, limit: 4) {
            edges {
                node {
                    id
                    description
                    handle
                    title
                    availableForSale
                    productType
                    priceRange {
                        minVariantPrice {
                            amount
                        }
                    }
                    variants {
                        id
                    }
                    images {
                        id
                        localFile{
                            url
                        }
                    }
                }
            }
        }
    }
`

export default Product